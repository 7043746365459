<template>
  <div>
    <v-sheet class="d-flex">
      <v-sheet class="px-2 d-flex justify-space-between align-center mx-auto pt-10" color="transparent" width="100%" max-width="1240">
        <h1>{{ $t('EducationPrograms') }}</h1>
        <ws-text-field
            v-model="search"
            :placeholder="$t('Search')"
            append-icon="mdi-magnify"
        />
      </v-sheet>
    </v-sheet>



    <v-sheet class="d-flex">
      <v-sheet class="px-2 mx-auto pt-10" color="transparent" width="100%" max-width="1240">


        <div v-for="(studyField,code) in studyFields" :key="code"

        >
          <div style="width: 400px">
            <h3 class="mb-5 wsACCENT"> {{ studyField }} </h3>
          </div>


          <div class="flex-grow-1">
            <div v-for="(item,i) in getStudyFieldSpecialties(code)" :key="i" >
              <router-link class="mt-5 noUnderline" :to="businessPublicLink(`education_program/${item.uuid}`)">
              <div class="d-flex">
                <img v-if="item.logo" :src="item.logo" width="250px" height="200px" class="mr-5 wsRoundedSemi" style="object-fit: cover" alt="">
                <div class="d-flex flex-column justify-center">
                  <h2 class="wsDARKER" >
                    {{ item.name }}
                  </h2>
                  <h5 class="wsDARKER">{{ $t('SpecialtyDegree') }}</h5>
                  <div v-for="(degree,j) in item.degrees || []" :key="code + i + j + 'degree'">
                    <h5 class="font-weight-regular wsDARKER">{{ degree.level }}</h5>
                  </div>


                  <h5 class="wsDARKER">{{ $t('SpecialtyCode') }} : <span class="font-weight-regular">{{ item.code }}</span> </h5>

                    <h4 class="wsATTENTION linkHover mt-5">{{ $t('MoreDetails') }}</h4>


                </div>

              </div>
              </router-link>

              <v-divider class="my-5" style="border-color : var(--wsBACKGROUND)"></v-divider>
            </div>


          </div>

        </div>

      </v-sheet>
    </v-sheet>


  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "publicEducationProgramsBlock",
  data() {
    return {
      items : []
    }
  },
  computed : {
    studyFields() {
      let fieldsObject = {}
      this.items.forEach(item => {
        fieldsObject[item.study_field] = item.study_field_name
      })
      return fieldsObject
    },
  },
  methods : {
    ...mapActions('wsu_structure' , [
        'GET_SPECIALTIES_PUBLIC'
    ]),

    getStudyFieldSpecialties(code) {
      return this.items.filter(item => item.study_field === code )
    },

    async initPage() {
      let result = await this.GET_SPECIALTIES_PUBLIC();
      if (!result) {
        return this.ERROR()
      }

      this.items = result.specialties || []

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>